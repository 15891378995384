import * as React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout/layout';
import Container from '../../components/container/container';
import Button from '../../components/button/button';
import { Col, Grid } from '../../components/grid/grid';
import ContactForm from '../../components/contact-form/contact-form';
import PropertyMap from '../../components/property-map/property-map';

import * as styles from './property.module.scss';

export default function PropertyPage({ data }) {
  const {
    markdownRemark: { frontmatter, html },
  } = data;

  return (
    <Layout
      title={`${frontmatter.title}, ${frontmatter.location}`}
      includeContactSection={false}
    >
      <div className={styles.property__intro}>
        <Container className={styles.property__intro__content}>
          <h1 className={styles.property__name}>{frontmatter.title}</h1>
          <p className={`lg ${styles.property__location}`}>
            {frontmatter.location}
          </p>
          <p className="lg flush">{frontmatter.propertyType}</p>
          <p className="lg txt-primary">{frontmatter.availableDate}</p>
          <Button href="#enquire">Enquire here</Button>
        </Container>
        <StaticImage
          src="../../images/property-main-img.png"
          alt=""
          placeholder="blurred"
          layout="fullWidth"
          className={styles.property__intro__image}
        />
      </div>

      <div className={styles.property__body}>
        <Container className={styles.property__body__content}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
        <StaticImage
          src="../images/properties/mile-house-lane-home-img.png"
          alt=""
          placeholder="blurred"
          layout="fullWidth"
          className={styles.property__body__image}
        />
      </div>

      <Grid>
        <Col>
          <StaticImage
            src="../../images/about-page-img-2.png"
            alt=""
            placeholder="blurred"
            layout="fullWidth"
          />
        </Col>
        <Col>
          <StaticImage
            src="../../images/about-page-img-3.png"
            alt=""
            placeholder="blurred"
            layout="fullWidth"
          />
        </Col>
        <Col>
          <StaticImage
            src="../../images/about-page-img-4.png"
            alt=""
            placeholder="blurred"
            layout="fullWidth"
          />
        </Col>
      </Grid>

      <Container id="enquire" className={styles.property__contact}>
        <h2 className="txt-primary">I’m interested in {frontmatter.title}</h2>
        <Grid gutters="md" valign="center">
          <Col>
            <p>
              To make an enquiry regarding this property, please get in touch by
              phone or email. Alternatively, send us a message using the form
              below, and we will contact you.
            </p>
            <p className={styles.property__contact__details}>
              Telephone: <a href="tel:+441234567890">01234 567890</a>
              <br />
              Email:{' '}
              <a href="mailto:info@timelessbs.co.uk">info@timelessbs.co.uk</a>
            </p>
            <ContactForm source={`${frontmatter.title} - Property Page`} />
          </Col>
          <Col>
            <PropertyMap
              lat={frontmatter.lat}
              lng={frontmatter.lng}
              // label={`${frontmatter.title}, ${frontmatter.location}`}
            />
          </Col>
        </Grid>
      </Container>

      <div className={styles.relatedProperties}>
        <Container>
          <h2>Our Properties</h2>

          <Grid gutters="xs">
            <Col width={4}>
              <div className={styles.relatedProperties__property}>
                <StaticImage
                  src="../../images/properties/property-thumb-example.png"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
                <div className={styles.relatedProperties__property__details}>
                  <h5 className="tight txt-primary flush">Title</h5>
                  <p>Location</p>
                </div>
              </div>
            </Col>
          </Grid>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        location
        lat
        lng
        propertyType
        availableDate(formatString: "MMMM yyyy")
      }
    }
  }
`;
