// extracted by mini-css-extract-plugin
export const property__intro = "property-module--property__intro--l8zYY";
export const property__intro__content = "property-module--property__intro__content--3NwtQ";
export const property__intro__image = "property-module--property__intro__image--2C33W";
export const property__name = "property-module--property__name--1yYEd";
export const property__location = "property-module--property__location--1ZemW";
export const property__body = "property-module--property__body--3cCYA";
export const property__body__content = "property-module--property__body__content--jXi_j";
export const property__body__image = "property-module--property__body__image--Q7xRO";
export const property__contact = "property-module--property__contact--2LOoP";
export const property__contact__details = "property-module--property__contact__details--X6rGj";
export const relaredProperties = "property-module--relaredProperties--qD2xK";
export const relatedProperties__property = "property-module--relatedProperties__property--O13qT";
export const relatedProperties__property__details = "property-module--relatedProperties__property__details--1732h";