import React from 'react';
import GoogleMapReact from 'google-map-react';

import * as styles from './property-map.module.scss';

function Pin() {
  return (
    <svg
      className={styles.pin}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 58 58"
    >
      <path
        d="M29,58A29,29,0,1,1,58,29,29,29,0,0,1,29,58ZM29,3A26,26,0,1,0,55,29,26,26,0,0,0,29,3Z"
        fill="#ab915b"
      />
      <path
        d="M30.75,26.33a12.06,12.06,0,0,1,.67,4h0v6.47a12.06,12.06,0,0,1-.67,4,8.79,8.79,0,0,1-.94-4h0V30.29a8.79,8.79,0,0,1,.94-4ZM29,44a12.46,12.46,0,0,1-.95-1.45A10.76,10.76,0,0,0,29,40.8a11.52,11.52,0,0,0,.94,1.77A12.38,12.38,0,0,1,29,44Zm-2.42-7.26V30.29a12.06,12.06,0,0,1,.67-4,8.79,8.79,0,0,1,.94,4v6.47h0a8.79,8.79,0,0,1-.94,4,12.06,12.06,0,0,1-.67-4ZM29,23a11.6,11.6,0,0,1,.94,1.45A11.52,11.52,0,0,0,29,26.25a11.52,11.52,0,0,0-.94-1.77A11.6,11.6,0,0,1,29,23Zm16.22-4.91V16.5H38.75A13.76,13.76,0,0,0,29,20.55a13.76,13.76,0,0,0-9.75-4.05H12.78v1.62h6.47a12.16,12.16,0,0,1,8.67,3.64A13.73,13.73,0,0,0,27,23.12a10.52,10.52,0,0,0-7.73-3.39H12.78v1.62h6.47a8.91,8.91,0,0,1,6.92,3.29,14.54,14.54,0,0,0-.67,1.85A7.33,7.33,0,0,0,19.25,23H12.78v1.62h6.47A5.71,5.71,0,0,1,25,30.29v6.47a13.63,13.63,0,0,0,.54,3.8,14.54,14.54,0,0,0,.67,1.85A14.76,14.76,0,0,0,27,43.93a12.53,12.53,0,0,0,.94,1.35A14,14,0,0,0,29,46.5a12.45,12.45,0,0,0,1.07-1.22A12.61,12.61,0,0,0,31,43.93a13,13,0,0,0,.8-1.52,13.2,13.2,0,0,0,.68-1.85,13.63,13.63,0,0,0,.54-3.8V30.29h0a5.71,5.71,0,0,1,5.71-5.71h6.47V23H38.75a7.33,7.33,0,0,0-6.25,3.53,13.2,13.2,0,0,0-.68-1.85,8.94,8.94,0,0,1,6.93-3.29h6.47V19.73H38.75A10.52,10.52,0,0,0,31,23.12a15.67,15.67,0,0,0-.94-1.36,12.16,12.16,0,0,1,8.67-3.64h6.47Z"
        fill="#ab915b"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default function PropertyMap({ lat, lng, label }) {
  console.log({ lat, lng });
  return (
    <div className={styles.wrapper}>
      <GoogleMapReact
        className={styles.map}
        bootstrapURLKeys={{ key: '' }}
        defaultCenter={{ lat, lng }}
        defaultZoom={14}
      >
        <Pin lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  );
}
